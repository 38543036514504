.header{
    height: 80px;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid rgba(130, 127, 152, 0.05);
    box-sizing: border-box;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    h3{
        color: #0D0E1E;
        opacity: 0.7;
        font-size: 20px;
        line-height: 175.75%;
    }
}