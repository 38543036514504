@import '../../Reusables.scss';

#modal-container {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  transform: scale(0);
  z-index: 1;
  &.animated-modal {
    transform: scale(1);
    .modal-background {
      background: rgba(14, 28, 21, 0.5);
      animation: fadeIn 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
      .modal {
        transform: translateX(-1500px);
        animation: roadRunnerIn 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
      }
    }
    &.out {
      animation: quickScaleDown 0s 0.5s linear forwards;
      .modal-background {
        animation: fadeOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
        .modal {
          animation: roadRunnerOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1)
            forwards;
        }
      }
    }
  }
  .modal-background {
    height: 100%;
    background: rgba(14, 28, 21, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10%;
    .modal {
      background: white;
      border-radius: 12px;
      position: relative;
      width: 100%;
      @include desktop {
        width: 40%;
      }
      .modal-text {
        padding: 30px 14px 30px;
        font-size: 14px;
        font-weight: 450;
        line-height: 137.4%;
        text-align: center;
        color: #0d0e1e;
      }
      .modal-buttons {
        display: flex;
        justify-content: space-between;
        background: rgba($color: #f2f3f7, $alpha: 0.5);
        padding: 25px 18px 22px;
        border-radius: 0px 0px 12px 12px;
        position: relative;
        .error{
          position: absolute;
          top: 5px;
          left: 0;
          right: 0;
        }
        button {
          width: 47%;
          font-size: 12px;
          font-weight: bold;
        }
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    background: rgba(14, 28, 21, 0);
  }
  100% {
    background: rgba(14, 28, 21, 0.5);
  }
}

@keyframes fadeOut {
  0% {
    background: rgba(14, 28, 21, 0.5);
  }
  100% {
    background: rgba(14, 28, 21, 0);
  }
}

@keyframes quickScaleDown {
  0% {
    transform: scale(1);
  }
  99.9% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes roadRunnerIn {
  0% {
    transform: translateY(-1500px) skewY(10deg) scaleY(1.3);
  }
  70% {
    transform: translateY(30px) skewY(0deg) scaleY(0.9);
  }
  100% {
    transform: translateY(0px) skewY(0deg) scaleY(1);
  }
}

@keyframes roadRunnerOut {
  0% {
    transform: translateY(0px) skewY(0deg) scaleY(1);
  }
  30% {
    transform: translateY(-30px) skewY(0deg) scaleY(0.9);
  }
  100% {
    transform: translateY(1500px) skewY(10deg) scaleY(1.3);
  }
}

//loader styles
@keyframes loader-animation {
  0% {
    left: -90%;
  }
  49% {
    left: 90%;
  }
  50% {
    left: 90%;
  }
  100% {
    left: -90%;
  }
}
.modal-loader {
  height: 2px;
  width: 100%;
  overflow: hidden;
  &.loader{
    height: 4px;
    position: fixed;
    top: 80px;
    right: 0;
    left: 0;
    .bar{
      height: 4px;
    }
  }
  .bar {
    position: relative;
    height: 2px;
    background-color: #276df7;
    animation-name: loader-animation;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
}
