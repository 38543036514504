.scanner-screen {
    padding: 112px 14px;
    min-height: 100vh;
    background: #FCFCFC;
    .back{
        display: none;
    }
    .scanner-content{
        background: #FFFFFF;
        border: 1px solid #F2F3F7;
        box-sizing: border-box;
        border-radius: 6px;
        padding: 16px 16px 50px;
        .scan-text{
            margin: 20px 0;
            text-align: center;
            font-weight: 400;
            font-size: 12px;
            color: #0D0E1E;
        }
        .cancel-button{
            width: 135px;
            height: 44px;
            background: #0D0E1E;
            border: 1px solid #0D0E1E;
            color: #FFFFFF;
            border-radius: 50px;
            font-weight: 400;
            letter-spacing: 0.05em;
            display: block;
            margin: 0 auto;
        }
    }
}