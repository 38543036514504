.completed-list{
    .completed-card{
        padding: 22px 16px;
        display: flex;
        align-items: center;
        background: #FFFFFF;
        border: 1px solid #F2F3F7;
        box-sizing: border-box;
        border-radius: 6px;
        margin-bottom: 10px;
        .badge{
            height: 40px;
            width: 40px;
            color: #FFFFFF;
            font-size: 13px;
            font-weight: bold;
            border-radius: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 12px;
        }
        .name-number{
            h4{
                color: #0D0E1E;
                font-weight: 350;
                font-size: 14px;
                line-height: 137.4%;
                text-transform: capitalize;
            }
            p{
                color: #68686C;
                opacity: 0.7;
                font-weight: 325;
                font-size: 12px;
                line-height: 198.8%;
            }
        }
        svg{
            margin-left: auto;
        }
    }
}