@import '../../Reusables.scss';

.test-1{
    @include mobile {
      font-size: 12px;
    }
    @include tablet {
      font-size: 24px;
    }
    @include desktop {
      font-size: 56px;
    }
}

.login-page{
  background: #FDF7F7;
  height: 100vh;
  position: relative;
  padding: 24px 5%;
  .lifebank-logo{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 104px;
    height: auto;
    margin-bottom: 34px;
    @include desktop {
      margin-bottom: 50px;
    }
  }
  .left-illustration{
    position: absolute;
    left: 0;
    bottom: 0;
  }
  .right-illustration{
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .login-card{
    position: relative;
    background: rgba(255, 255, 255, 0.7);
    border: 1px solid rgba(130, 127, 152, 0.1);
    border-radius: 15px;
    padding: 40px 20px;
    width: 100%;
    margin: 0 auto;
    z-index: 10;
    overflow: hidden;
    .loader {
      top: 0;
      position: absolute;
      .bar{
        border-radius: 2px;
      }
    }
    @include desktop {
      width: 30%;
    }
    @include tablet {
      width: 50%;
    }
    .title{
      font-weight: bold;
      font-size: 24px;
      line-height: 30px;
      color: #000000;
      text-align: center;
      margin-bottom: 10px;
    }
    .subtitle{
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: #18131A;
      text-align: center;
      margin-bottom: 42px;
    }
    label{
      #password {
        margin-bottom: 0;
      }
      .show-hide{
        position: absolute;
        bottom: 10px;
        right: 20px;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        color: #3B3B3B;
      }
      #password{
        padding-right: 70px;
      }
    }
    .server-error{
      text-align: center;
      color: #9c2b2e;
      font-size: 10px;
      margin-top: -15px;
    }
    .forgot-password{
      text-align: right;
      margin-top: 16px;
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      color: #276DF7;
      cursor: pointer;
    }
    button{
      width: 219px;
      display: block;
      margin: 0 auto;
      margin-top: 30px;
    }
  }
}