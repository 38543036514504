.title-header{
    height: 80px;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid rgba(130, 127, 152, 0.05);
    // padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    h3{
        font-weight: 400;
        font-size: 14px;
        line-height: 175.75%;
        color: #37474F;
    }
    .back{
        position: absolute;
        left: 20px;
        font-weight: 350;
        font-size: 14px;
        color: rgba(78, 79, 90, 0.54);
        display: flex;
        align-items: center;
        img{
            margin-right: 10px;
        }
    }
}