.success-div{
    width: 100%;
    height: auto;
    position: relative;
    img{
        width: 100%;
        height: auto;
        border-radius: 12px;
    }
    .close-icon{
        height: 32px;
        width: 32px;
        position: absolute;
        top: -42px;
        right: 0;
    }
}