.pending-request-screen{
    min-height: 100vh;
    background: #FCFCFC;
    padding: 112px 14px;
    .card{
        background: #FFFFFF;
        border: 1px solid #F2F3F7;
        border-radius: 6px;
        padding: 24px 16px 48px;
        .details{
            display: flex;
            align-items: center;
            margin-bottom: 22px;
            .badge{
                height: 40px;
                width: 40px;
                color: #FFFFFF;
                font-size: 13px;
                font-weight: bold;
                border-radius: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 12px;
            }
            .name{
                font-weight: 400;
                font-size: 14px;
                line-height: 137.4%;
                color: #0D0E1E;
            }
            .kit-number{
                margin-left: auto;
                text-align: right;
                color: #68686C;
                p{
                    font-size: 12px;
                }
            }
        }
        .address-div{
            padding: 14px;
            background: #F8F9FB;
            border-radius: 6px;
            margin-bottom: 15px;
            .top{
                display: flex;
                justify-content: space-between;
                align-items: center;
                p{
                    font-size: 12px;
                    color: #1D1E1C;
                    opacity: 0.7;
                }
            }
            .main{
                display: none;
                margin-top: 16px;
                font-size: 14px;
                color: #1D1E1C;
                opacity: 0.7;
                line-height: 150.75%;
            }
            &.show{
                .top{
                    img{
                        transform: rotate(180deg);
                    }
                }
                .main{
                    display: block;
                }
            }
        }
        .actions{
            margin: 0 20px;
            padding-top: 15px;
            border-top: 1px solid #F2F3F7;
            button{
                width: 100%;
                &:first-child{
                    margin-bottom: 15px;
                }
                img{
                    margin-right: 6px;
                    margin-bottom: -2px;
                }
            }
        }
    }
    .reschedule-reason{
        border-top: 1px solid #F2F3F7;
        border-bottom: 1px solid #F2F3F7;
        margin: 20px 14px 0;
        .item{
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 48px;
            p{
                font-weight: 350;
                font-size: 12.4458px;
                line-height: 15px;
                color: #0D0E1E;
                opacity: 0.7;
            }
        }
        .item:not(:last-child){
            border-bottom: 1px solid #F2F3F7;
        }
    }
}