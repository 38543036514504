.change-password-screen {
  min-height: 100vh;
  background: #f7f7f9;
  padding: 112px 14px;
  .input-container {
    .input-name {
      position: initial;
      font-weight: 400;
      font-size: 14px;
      line-height: 175.75%;
      color: #37474f;
    }
    input {
      border: none;
      margin-top: 8px;
      font-weight: 350;
      font-size: 16px;
      line-height: 19px;
      padding: 0 20px;
      &::placeholder {
        color: #C0BECA;
        opacity: 1;
      }

      &:-ms-input-placeholder {
        color: #C0BECA;
      }

      &::-ms-input-placeholder {
        color: #C0BECA;
      }
    }
  }
  button{
    width: 80%;
    display: block;
    margin: 30px auto;
  }
}
