.nav-footer{
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    height: 68px;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12), 0px 8px 8px rgba(0, 0, 0, 0.24);
    border-radius: 2px;
    padding: 0 28px 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .nav-item{
        display: flex;
        flex-direction: column;
        align-items: center;
        svg{
            margin-bottom: 2px;
        }
        p{
            font-weight: 350;
            font-size: 12px;
            line-height: 16px;
        }
        p.active{
            color: #FF0000;
        }
        p.default{
            color: #C0BECA;
        }
    }
}