.tabs{
    width: 100%;
    height: 50px;
    padding: 5px;
    background: rgba(130, 127, 152, 0.1);
    border-radius: 12px;
    display: flex;
    .tab{
        flex-grow: 1;
        flex-basis: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        color: #37474F;
        font-weight: 400;
        background-color: rgba($color: #FFFFFF, $alpha: 0);
        border-radius: 12px;
        transition: background-color 0.25s ease-in;
        text-transform: capitalize;
        &.active{
            background-color: rgba($color: #FFFFFF, $alpha: 1);
            color: #276DF7;
        }
    }
}