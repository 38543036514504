.account-screen {
    padding: 112px 14px;
    min-height: 100vh;
    background: #FCFCFC;
    .account-details{
        background: #FFFFFF;
        border: 1px solid #F2F3F7;
        border-radius: 12px;
        .details-top{
            background: #EEEFF3;
            padding: 30px;
            border-radius: 12px 12px 0px 0px;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            overflow: hidden;
            .badge{
                height: 60px;
                width: 60px;
                color: #FFFFFF;
                font-weight: bold;
                font-size: 20px;
                border-radius: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 5px;
            }
            .name{
                font-weight: 500;
                font-size: 16px;
                line-height: 137.4%;
                color: #0D0E1E;
                margin-bottom: 5px;
            }
            .text{
                font-weight: 400;
                font-size: 12px;
                line-height: 198.8%;
                color: #68686C;
                text-align: center;
            }
            .left{
                position: absolute;
                top: 0;
                left: 0;
                border-radius: 12px 0 0 0;
                opacity: 0.5;
            }
            .right{
                position: absolute;
                right: 0;
                bottom: 0;
                opacity: 0.5;
            }
        }
        .details-bottom{
            padding: 24px 20px;
            .item-row{
                padding: 12px 0;
                display: flex;
                justify-content: space-between;
                .label{
                    color: #68686C;
                    text-transform: capitalize;
                    font-weight: 350;
                    font-size: 14px;
                    line-height: 175.75%;
                }
                .content{
                    max-width: 60%;
                    color: #0D0E1E;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 175.75%;
                    text-align: right;
                    text-transform: uppercase;
                    opacity: 0.7;
                }
            }
            .collect-sample{
                padding: 18px 0;
                border-top: 1px solid #F2F3F7;
                border-bottom: 1px solid #F2F3F7;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .check{
                    width: 14px;
                    height: 14px;
                    background: #FCF9F9;
                    opacity: 0.4;
                    border: 1px solid #68686C;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    transition: opacity 0.15ms ease-in;
                    .dot{
                        display: none;
                        width: 8px;
                        height: 8px;
                        background: #276DF7;
                        border-radius: 50%;
                    }
                    &.checked{
                        opacity: 1;
                        background: #FFFFFF;
                        border: 1px solid #276DF7;
                        .dot{
                            display: block;
                        }
                    }
                }
                p{
                    font-weight: 350;
                    font-size: 14px;
                    color: #0D0E1E;
                    opacity: 0.7;
                }
            }
            button{
                width: 100%;
                margin-top: 36px;
                img{
                    margin-right: 6px;
                }
            }
        }
    }
}