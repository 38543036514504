.edit-profile-screen{
    min-height: 100vh;
    background: #F7F7F9;
    padding: 112px 14px;
    h4{
        font-weight: 400;
        font-size: 14px;
        line-height: 175.75%;
        color: #37474F;
        margin-bottom: 8px;
    }
    .input-container{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        label{
            margin-bottom: 15px;
            &.half{
                width: 48%;
            }
            .input-name{
                font-size: 13px;
                left: 15px;
                color: #C0BECA;
            }
            input, textarea{
                border: none;
                padding: 30px 15px 9px;
                font-weight: 400;
            }
            textarea{
                padding-top: 34px;
                font-family: inherit;
                resize: none;
                height: 90px;
            }
        }
    }
    button{
        // margin-top: 30px;
        width: 80%;
        display: block;
        margin: 30px auto;
    }
}