.profile-screen{
    min-height: 100vh;
    background: #F7F7F9;
    padding: 80px 0 100px;
    .top-card{
        background: #FFFFFF;
        border: 1px solid #F1F3FA;
        padding: 40px 18px;
        .name-id-badge{
            display: flex;
            align-items: center;
            .badge{
                font-weight: bold;
                font-size: 17px;
                color: #FFFFFF;
                height: 51px;
                width: 51px;
                border-radius: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #FFA300;
                margin-right: 15px;
            }
            .name{
                font-weight: 350;
                font-size: 18px;
                line-height: 137.4%;
                color: #0D0E1E;
            }
            .id{
                font-weight: 350;
                font-size: 14px;
                line-height: 17px;
                color: #737373;
            }
        }
        .line{
            border: 0.5px solid #F2F3F7;
            width: 90%;
            margin: 30px auto 20px;
        }
        .buttons{
            display: flex;
            justify-content: space-between;
            button{
                width: 48%;
                font-size: 13px;
            }
        }
    }
    .information{
        padding: 34px 14px;
        h4{
            font-weight: 400;
            font-size: 14px;
            line-height: 175.75%;
            color: #37474F;
            margin-bottom: 8px;
        }
        .info-cards{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .info-card{
                min-height: 60px;
                padding: 10px 15px;
                background: #FFFFFF;
                border-radius: 8px;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin-bottom: 15px;
                &.half{
                    width: 48%;
                }
                &:last-child{
                    margin-bottom: 0;
                }
                .title{
                    font-weight: 350;
                    font-size: 13px;
                    color: #C0BECA;
                    margin-bottom: 6px;
                }
                .content{
                    font-weight: 350;
                    font-size: 16px;
                    color: rgba(55, 71, 79, 0.5);
                }
            }
        }
    }
    .manage-account{
        h4{
            font-weight: 350;
            font-size: 14px;
            line-height: 175.75%;
            color: #37474F;
            margin-left: 14px;
            margin-bottom: 10px;
        }
        .diable-account{
            background: #FFFFFF;
            height: 60px;
            padding: 0 14px;
            display: flex;
            align-items: center;
            font-weight: 350;
            font-size: 16px;
            line-height: 19px;
            color: #FE2A2A;
        }
    }
}