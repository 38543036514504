*{
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

h1,h2,h3,h4,h5,h6,p{
  margin: 0;
}

label{
  width: 100%;
  position: relative;
  display: inline-block;
  margin-bottom: 26px;
  .input-name{
    position: absolute;
    left: 20px;
    top: 9px;
    font-weight: normal;
    font-size: 12px;
    // color: #827F98;
    color: #18131A;
    opacity: 0.8;
  }
  .input-error{
    position: absolute;
    right: 20px;
    top: 9px;
    font-weight: normal;
    font-size: 10px;
    color: #9c2b2e;
  }
  input, textarea {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid rgba(130, 127, 152, 0.4);
    border-radius: 8px;
    height: 60px;
    padding: 30px 20px 9px;
    color: #3B3B3B;
    font-weight: 600;
    font-size: 16px;
    &:focus{
      border: 1px solid #276DF7;
      outline: none;
    }
    &:active{
      outline: none;
    }
    &.error{
      border: 1px solid #9c2b2e;
    }
  }
  input[type=text], input[type=password] { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
}

button{
  text-transform: capitalize;
  cursor: pointer;
  &:active{
    outline: none;
  }
  &:focus{
    outline: none;
  }
  &:disabled{
      opacity: 0.5;
  }
}

.primary-button{
  color: #FFFFFF;
  background: #276DF7;
  border: 1px solid #276DF7;
  font-size: 16px;
  height: 50px;
  border-radius: 50px;
  font-weight: 400;
}

.primary-button-outline{
  color: #276DF7;
  background: transparent;
  border: 1.5px solid #276DF7;
  font-size: 16px;
  height: 50px;
  border-radius: 50px;
  font-weight: 400;
}

.red-button{
  background: rgba($color: #FE2A2A, $alpha: 0.05);
  border: none;
  color: #FE2A2A;
  border-radius: 15px;
  &.signout{
    height: 30px;
    width: 80px;
    font-weight: 400;
    font-size: 12px;
  }
}

.badge{
  text-transform: uppercase;
}

.page-error{
  text-align: center;
  color: #68686C;
  font-size: 14px;
  padding: 10px;
}

.error{
  font-size: 12px;
  color: #FE2A2A;
  text-align: center;
}