.completed-request-screen{
    min-height: 100vh;
    background: #FCFCFC;
    padding: 112px 14px;
    .card{
        background: #FFFFFF;
        border: 1px solid #F2F3F7;
        border-radius: 6px;
        padding: 24px 16px 48px;
        .details{
            display: flex;
            align-items: center;
            margin-bottom: 22px;
            .badge{
                height: 40px;
                width: 40px;
                color: #FFFFFF;
                font-size: 13px;
                font-weight: bold;
                border-radius: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 12px;
            }
            .name{
                font-weight: 400;
                font-size: 14px;
                line-height: 137.4%;
                color: #0D0E1E;
            }
            .kit-number{
                margin-left: auto;
                text-align: right;
                color: #68686C;
                p{
                    font-size: 12px;
                }
            }
        }
        .delivery-details{
            margin: 0 20px;
            padding-top: 15px;
            border-top: 1px solid #F2F3F7;
            .item-row{
                padding: 12px 0;
                display: flex;
                justify-content: space-between;
                .label{
                    color: #68686C;
                    text-transform: capitalize;
                    font-weight: 350;
                    font-size: 14px;
                    line-height: 175.75%;
                }
                .content{
                    max-width: 60%;
                    color: #0D0E1E;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 175.75%;
                    text-align: right;
                    text-transform: uppercase;
                }
            }
        }
    }
}