.history-list{
    margin-right: -14px;
    margin-left: -14px;
    .day-label{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        border-radius: 10px;
        width: 100px;
        height: 25px;
        color: #37474F;
        font-weight: 350;
        font-size: 12px;
        background-color: rgba($color: #EBF0FC, $alpha: 0.7);
        margin-bottom: 10px;
    }
    .list{
        background: #FFFFFF;
        border: 1px solid #F2F3F7;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 14px 18px;
        margin-bottom: 30px;
        .item{
            padding: 12px 0;
            display: flex;
            align-items: center;
            position: relative;
            .badge{
                height: 40px;
                width: 40px;
                color: #FFFFFF;
                font-size: 13px;
                font-weight: bold;
                border-radius: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 12px;
            }
            .name-number{
                h4{
                    color: #0D0E1E;
                    font-weight: 350;
                    font-size: 14px;
                    line-height: 137.4%;
                }
                p{
                    color: #68686C;
                    opacity: 0.7;
                    font-weight: 325;
                    font-size: 12px;
                    line-height: 198.8%;
                }
            }
            svg{
                margin-left: auto;
            }
            .line{
                border: 1px solid #F2F3F7;
                position: absolute;
                bottom: 0;
                right: 0;
                left: 40px;
            }
            &:last-child{
                .line{
                    display: none;
                }
            }
        }
    }
}