.empty-feed{
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    svg{
        margin-bottom: 25px;
    }
    h4{
        color: #0D0E1E;
        text-transform: capitalize;
        margin-bottom: 5px;
    }
    p{
        font-weight: 350;
        font-size: 12px;
        line-height: 175.75%;
        color: #68686C;
        text-align: center;
    }
}